import { Box, Stack, Typography } from "@mui/material";
import { useCompanyInfo } from "../../hooks/useCompanyInfo";
import useScreenType from "../../hooks/useScreenType";

export default function FooterView() {
    const screenType = useScreenType()
    const company = useCompanyInfo()

    const getStackDirection = () => {
        if (screenType.isMobile) {
            return 'column'
        } else {
            return 'row'
        }
    }
    const getStackAlignItems = () => {
        if (screenType.isMobile) {
            return 'start'
        } else {
            return 'end'
        }
    }
    const getStackJustifyContents = () => {
        if (screenType.isMobile) {
            return 'left'
        } else {
            return 'left'
        }
    }
    const getBoxPadding = () => {
        if (screenType.isMobile) {
            return '48px 16px'
        } else {
            return '48px 160px'
        }
    }

    return (
        <Box sx={{ backgroundColor: '#272D3C', color: '#ABABAB', padding: getBoxPadding() }}>
            <Stack direction={getStackDirection()} spacing='16px' alignItems={getStackAlignItems()} justifyContent={getStackJustifyContents()} sx={{ height: '100%' }}>
                <Stack sx={{ flexGrow: 1, height: '100%', alignItems: 'start' }}>
                    {/* <Typography sx={{ fontSize: '16px', fontWeight: '700', lineHeight: '24px' }}>
                        Payment Method
                    </Typography> */}
                </Stack>
                <Typography sx={{ fontSize: '10px', fontWeight: '600', lineHeight: '120%' }}>
                    {`Copyright © 2024 ${company?.name ?? ''}. All rights reserved.`}
                </Typography>
            </Stack>
        </Box>
    )
}