import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertDialogContext, ClearBalancePopupContext, CompanyInfoContext, UserInfoContext } from '../App';
import Constants, { keys_language_code, languages } from '../constants'
import Utils from "../utils/utils";
import RunningTextBar from "./runningText/runningTextBar";
import { Box, Button, IconButton, ListItemIcon, Menu, MenuItem, Popover, Stack, Tooltip, Typography } from '@mui/material'
import { ExpandMore, Logout, Refresh } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import LoginButton from "./button/loginButton";
import RegisterButton from "./button/registerButton";
import { useTemplateVariables } from "../utils/templateUtils";
import AvatarView from "./views/avatarView";
import UserSummaryDetailsView from "./views/userSummaryDetailsView";
import { request } from "../feathers/feathersClient";
import { useUserInfo } from "../hooks/useUserInfo";
import CircularProgressWithLabel from "./progressIndicator/circularProgressWithLabel";
import { useTimer } from "react-timer-hook";
import DepositButton from "./button/depositButton";
import WithdrawalButton from "./button/withdrawalButton";
import useScreenType from "../hooks/useScreenType";

export default function AppTopBar() {
    const navigate = useNavigate()
    const location = useLocation()

    // Contexts
    const userInfo = useUserInfo()
    const { setUserInfo } = useContext(UserInfoContext)
    const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext);
    const { setAlertDialog } = useContext(AlertDialogContext);
    const { t, i18n } = useTranslation()
    const screenType = useScreenType()
    const template = useTemplateVariables()
    const client = request({
        setAlertDialog: setAlertDialog, 
        setCompanyInfo: setCompanyInfo, 
        setUserInfo: setUserInfo, 
        navigate: navigate
    })
    const { setOpenClearBalance } = useContext(ClearBalancePopupContext)

    const availableLanguages = companyInfo?.languages ?? []
    const currencyCode = companyInfo?.company?.currencyCode ?? 'MYR'

    //Template design
    const templateDesign = companyInfo?.company?.templateDesign ?? Constants.templateDesignClassic
    const headerBackgroundType = companyInfo?.company?.headerBackgroundType ?? Constants.backgroundTypeImage

    //States
    const [languageCode, setLanguageCode] = useState(Utils.shared.getDefaultLanguage({ companyInfo: companyInfo, i18n: i18n }))

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const [openDashboardAnchorEl, setOpenDashboardAnchorEl] = useState(null)
    const openDashboard = Boolean(openDashboardAnchorEl)

    const [showRefreshButton, setShowRefreshButton] = useState(true)

    let scheduleSeconds = 30
    const countDown = new Date()
    countDown.setSeconds(countDown.getSeconds() + scheduleSeconds)
    const { restart, seconds, isRunning } = useTimer({ autoStart: false, expiryTimestamp: countDown })
    const restartTimer = () => {
        const newCountDown = new Date()
        newCountDown.setSeconds(newCountDown.getSeconds() + scheduleSeconds)
        restart(newCountDown)
    }

    //Theme Color
    var color = Constants.defaultMainColor
    let mainFontColor = companyInfo?.company?.mainFontColor
    if (mainFontColor && mainFontColor.length > 0) {
        color = mainFontColor
    }

    var bgImageUrl = `${process.env.PUBLIC_URL}/images/header.jpeg`
    let headerBackgroundImage = companyInfo?.company?.headerBackgroundImage
    if (headerBackgroundImage && headerBackgroundImage.length > 0) {
        bgImageUrl = Utils.shared.getCompanyImagesLink(companyInfo, headerBackgroundImage, companyInfo?.company?._id)
    }

    var logoImageUrl = `${process.env.PUBLIC_URL}/images/defaultLogoIcon.png`
    let logoPlayerSite = companyInfo?.company?.logoPlayerSite
    if (logoPlayerSite && logoPlayerSite.length > 0) {
        logoImageUrl = Utils.shared.getCompanyImagesLink(companyInfo, logoPlayerSite, companyInfo?.company?._id)
    }

    //Localization
    let selectedLanguage = languages.find((e) => e.code === languageCode) ?? languages[0]
    const getLanguageMenu = () => {
        //First get active languages
        let activeLanguages = availableLanguages.length > 0 ? languages.filter((lang) => {
            let index = availableLanguages.findIndex((al) => al.language === lang.code)

            return index > -1
        }) : languages

        return activeLanguages.map((e, index) => {
            let selected = e.code === selectedLanguage.code

            return (
                <MenuItem key={`languageMenu${index}`} selected={selected} style={{ color: color }} onClick={() => {
                    setLanguageCode(e.code)
                }} >
                    <ListItemIcon>
                        <img src={`${process.env.PUBLIC_URL}/images/flags/${e.icon}`} alt='language' height='30px' width='auto' loading="lazy">
                        </img>
                    </ListItemIcon>
                    {e.title}
                </MenuItem>
            )
        })
    }
    const getRefreshButton = () => {
        return showRefreshButton ? (
            <Tooltip title='Refresh balance'>
                <IconButton sx={{ margin: 0, padding: 0 }} onClick={(e) => {
                    e.stopPropagation()

                    //Refresh balance
                    client.create('get-player-games-balance', {
                        company: companyInfo?.company?._id,
                        user: userInfo?._id,
                        isBOWithdraw: false
                    }, null, (response) => {

                    }, null, true, () => {
                        //Reset timer here
                        setShowRefreshButton(false)
                        restartTimer()
                    })
                }}>
                    <Refresh htmlColor={'#828282'} fontSize='small' />
                </IconButton>
            </Tooltip>
        ) : (
            <Tooltip title='Please wait for next available refresh'>
                <CircularProgressWithLabel value={seconds} max={scheduleSeconds} />
            </Tooltip>
        )
    }
    const getLoginButtons = () => {
        if (templateDesign === Constants.templateDesignModern) {
            if (!screenType.isMobile && !screenType.isTablet) {
                //Check if is logged in
                if (userInfo) {
                    let fullName = userInfo?.fullName ?? ''
                    let isPlayerSiteDisplayCurrency = companyInfo?.company?.isPlayerSiteDisplayCurrency ?? true
                    let playerVirtualCredits = userInfo?.playerVirtualCredits ?? 0

                    return (
                        <Button sx={{ padding: '0px', margin: '0px', minWidth: '0px' }} onClick={(event) => {
                            setOpenDashboardAnchorEl(event.currentTarget)
                        }}>
                            <Stack direction='row' spacing='8px' alignItems={'center'}>
                                <AvatarView />
                                <Stack alignItems={'start'}>
                                    <Stack direction={'row'} alignItems={'center'} spacing='5px'>
                                        <div style={{
                                            fontSize: '10px',
                                            fontWeight: '400',
                                            lineHeight: '120%',
                                            color: '#828282',
                                            textTransform: 'uppercase'
                                        }}>
                                            {'BALANCE'}
                                        </div>
                                        {getRefreshButton()}
                                    </Stack>
                                    <Stack direction={'row'} alignItems={'start'} spacing={'2px'} sx={{
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        lineHeight: '24px',
                                        letterSpacing: '1.28px',
                                        color: template.mainFontColor,
                                        textTransform: 'uppercase'
                                    }}>
                                        {
                                            isPlayerSiteDisplayCurrency ? (
                                                <div style={{
                                                    fontSize: '10px',
                                                    fontWeight: '600',
                                                    lineHeight: '20px',
                                                    color: template.mainFontColor,
                                                    textTransform: 'uppercase'
                                                }}>{currencyCode}</div>
                                            ) : null
                                        }
                                        <div>{Utils.shared.getAmountString(playerVirtualCredits, '', companyInfo?.company)}</div>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Button>

                    )
                } else {
                    return (
                        <Stack direction='row' spacing='16px'>
                            <LoginButton fullWidth={false} />
                            <RegisterButton fullWidth={false} />
                        </Stack>
                    )
                }
            }
        }

        return null
    }
    const getInnerWidth = () => {
        if (templateDesign === Constants.templateDesignModern) {
            if (screenType.isMobile || screenType.isTablet) {
                return `calc(100vw - 24px)`
            } else {
                return `calc(100vw - 320px)`
            }
        } else {
            return '100vw'
        }
    }
    const getPadding = () => {
        if (templateDesign === Constants.templateDesignModern) {
            if (screenType.isMobile || screenType.isTablet) {
                return '12px 12px 12px 12px'
            } else {
                return '12px 160px 12px 160px'
            }
        } else {
            return '0px'
        }
    }
    const getMainHeight = () => {
        if (templateDesign === Constants.templateDesignModern) {
            return '64px'
        } else {
            return '84px'
        }
    }
    const getBackgroundHeight = () => {
        if (templateDesign === Constants.templateDesignModern) {
            return '64px'
        } else {
            return '60px'
        }
    }
    const getHeight = () => {
        if (templateDesign === Constants.templateDesignModern) {
            return 'calc(64px - 24px)'
        } else {
            return '60px'
        }
    }
    const getRunningTextBar = () => {
        if (templateDesign === Constants.templateDesignModern) {
            return null
        } else {
            return (
                <RunningTextBar />
            )
        }
    }
    const getBackgroundColor = () => {
        if (headerBackgroundType === Constants.backgroundTypeColor) {
            let headerBackgroundColor = companyInfo?.company?.headerBackgroundColor
            if (headerBackgroundColor) {
                return headerBackgroundColor
            }
        }

        return 'black'
    }
    const getBackgroundImage = () => {
        if (headerBackgroundType === Constants.backgroundTypeImage) {
            return bgImageUrl
        }

        return null
    }
    const getLogoutButton = () => {
        return (
            <Button sx={{
                padding: '0px 16px',
                paddingBottom: '16px',
                width: '100%'
            }} onClick={() => {
                client.performLogout(() => {
                    setUserInfo(null)
                    setOpenDashboardAnchorEl(null)

                    Utils.shared.getPlayerSiteCompanyInfo(setCompanyInfo, setUserInfo, setAlertDialog, navigate, () => {
                        navigate('/', { replace: true })
                    })
                })
            }}>
                <Stack spacing='8px' alignItems={'center'} justifyContent={'start'} direction={'row'} sx={{
                    color: '#828282',
                    width: '100%'
                }}>
                    <Logout />
                    <Typography sx={{
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '22px',
                    }}>
                        {t('GLOBAL_LOGOUT')}
                    </Typography>
                </Stack>
            </Button>
        )
    }

    //Use Effects
    useEffect(() => {
        //Set i18n language whenever language is changed
        i18n.changeLanguage(languageCode)
        Utils.shared.storeLocalStorage(keys_language_code, languageCode)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageCode])
    useEffect(() => {
        //Do things when route changes
        setOpenDashboardAnchorEl(null)
    }, [location])
    useEffect(() => {
        if (seconds === 0 && !isRunning) {
            //Enable refresh button
            setShowRefreshButton(true)
        }
    }, [seconds, isRunning])

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'sticky',
            top: 0,
            width: '100vw',
            maxWidth: '100vw',
            zIndex: '100',
            height: getMainHeight(),
        }}>
            <div style={{
                backgroundColor: getBackgroundColor(),
                height: getBackgroundHeight(),
                backgroundImage: getBackgroundImage() ? `url(${getBackgroundImage()})` : null,
                backgroundSize: 'cover',
                margin: 0,
                paddingRight: '0',
            }}>
                <Stack direction='row' spacing='16px' sx={{
                    width: getInnerWidth(),
                    padding: getPadding(),
                    alignItems: 'center'
                }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Button sx={{ backgroundColor: 'transparent', margin: 0, padding: 0 }} onClick={() => {
                            Utils.shared.getPlayerSiteCompanyInfo(setCompanyInfo, setUserInfo, setAlertDialog, navigate, () => {
                                navigate('/', { replace: true })
                            })
                        }} >
                            <img src={logoImageUrl} alt='logo' loading="lazy" height={getHeight()} width='auto' style={{ maxWidth: 'calc(100vw - 110px)', maxHeight: getHeight() }} >
                            </img>
                        </Button>
                    </Box>
                    {
                        getLoginButtons()
                    }
                    <Button sx={{ backgroundColor: 'transparent', margin: 0, padding: 0, textTransform: 'none', minWidth: '0px' }} onClick={(event) => {
                        setAnchorEl(event.currentTarget)
                    }} >
                        <Stack direction={'row'} spacing={'4px'} alignItems={'center'}>
                            <img src={`${process.env.PUBLIC_URL}/images/flags/${selectedLanguage.icon}`} alt='language' height='24px' width='auto' loading="lazy" />
                            {
                                (screenType.isMobile) ? null : (
                                    <Typography style={{ color: color, fontSize: '14px', paddingLeft: '4px' }}>
                                        {selectedLanguage.title}
                                    </Typography>
                                )
                            }
                            <ExpandMore htmlColor={color} fontSize="small" />
                        </Stack>
                    </Button>
                </Stack>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => {
                        setAnchorEl(null)
                    }}
                    onClick={() => {
                        setAnchorEl(null)
                    }}
                    slotProps={{
                        paper: {
                            style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' }
                        }
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    {getLanguageMenu()}
                </Menu>
                <Popover
                    open={openDashboard}
                    anchorEl={openDashboardAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    slotProps={{
                        paper: {
                            sx: {
                                maxWidth: '360px',
                                borderRadius: '16px',
                                boxShadow: '0px 4px 4px 0px rgba(23, 53, 89, 0.16)',
                            }
                        }
                    }}
                    onClose={() => {
                        setOpenDashboardAnchorEl(null)
                    }}
                >
                    <UserSummaryDetailsView />
                    <Stack direction='row' spacing='16px' sx={{
                        padding: '16px'
                    }}>
                        <DepositButton buttonOnly={true} />
                        <WithdrawalButton buttonOnly={true} />
                    </Stack>
                    {Utils.shared.getClearBalanceButton({ 
                        companyInfo: companyInfo,
                        userInfo: userInfo,
                        template: template,
                        setOpenClearBalance: setOpenClearBalance,
                        translator: t
                     })}
                    {getLogoutButton()}
                </Popover>
            </div>
            {getRunningTextBar()}
        </div>
    );
}